import { SplitNumber } from '@core/utils'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React from 'react'

import PayrollDetails from './PayrollDetails'
import PayrollInvoicedDetails from './PayrollInvoicedDetails'

export const parserPayrollsBasePayment = (payrolls, invoiceTotal, loading, onRequestChange, month) =>
  payrolls.map((payroll) => ({
    id: payroll.id,
    // fixme
    gross_salary: `${payroll?.company_currency?.sign || payroll?.company_currency?.short_code} ${SplitNumber(
      payroll?.gross_pay_in_company_currency
    )}`,
    total_amount: `${payroll?.company_currency?.sign || payroll?.company_currency?.short_code} ${SplitNumber(
      payroll?.total_amount_in_company_currency
    )}`,
    name: payroll.user.full_name,
    avatar: payroll.user.avatar,
    team: payroll?.contract?.department,
    payroll_manager: payroll?.created_by?.full_name,
    invoice_id: payroll.aggregated_invoice?.id,
    status: `payroll_${payroll.state}`,
    ...payroll,
    month: moment(payroll.month).format('DD MMM YYYY'),
    country: payroll?.country?.name,
    disabled: payroll.state !== 'approved_by_admin',
    collapseContent: loading ? (
      <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 200 }}>
        <DotWave size={32} speed={1} color="black" />
      </div>
    ) : (
      <PayrollDetails payroll={payroll} invoiceTotal={invoiceTotal} onRequestChange={onRequestChange} />
    ),
  }))

export const parserInvoicedPayrollsBasePayment = (payrolls, invoiceTotal, loading, onRequestChange, month) =>
  payrolls.map((payroll) => ({
    id: payroll.id,
    // fixme
    gross_salary: `${payroll?.company_currency?.sign || payroll?.company_currency?.short_code} ${SplitNumber(
      payroll?.gross_pay_in_company_currency
    )}`,
    total_amount: `${payroll?.company_currency?.sign || payroll?.company_currency?.short_code} ${SplitNumber(
      payroll?.total_amount_in_company_currency
    )}`,
    name: payroll.user.full_name,
    avatar: payroll.user.avatar,
    team: payroll?.contract?.department,
    payroll_manager: payroll?.created_by?.full_name,
    invoice_id: payroll.aggregated_invoice?.id,
    status: `payroll_${payroll.state}`,
    ...payroll,
    month: moment(payroll.month).format('DD MMM YYYY'),
    country: payroll?.country?.name,
    disabled: payroll.state !== 'approved_by_admin',
    collapseContent: loading ? (
      <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 200 }}>
        <DotWave size={32} speed={1} color="black" />
      </div>
    ) : (
      <PayrollInvoicedDetails payroll={payroll} invoiceTotal={invoiceTotal} onRequestChange={onRequestChange} />
    ),
  }))
