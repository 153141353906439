import CustomTable from '@atoms/Table/CustomTable'
import { useClientPagination } from '@core/hooks/useClientPagination'
import { formatMinutesToHours } from '@core/utils'

import { LIMIT } from './constants'

export const TIMESHEET_FIELDS = [
  {
    title: 'Email',
    key: 'email',
    type: 'string',
    maxWidth: 400,
    minWidth: 300,
  },
  {
    title: 'Total hours',
    key: 'aggregated_minutes',
    type: 'string',
    maxWidth: 300,
    minWidth: 200,
  },
]
export const CorrectDataTable = ({ timesheetFields }) => {
  const timesheets = timesheetFields.map((field) => ({
    ...field,
    aggregated_minutes:
      typeof field.aggregated_minutes === 'number'
        ? formatMinutesToHours(field.aggregated_minutes)
        : field.aggregated_minutes,
  }))
  const { currentPage, currentData, goToPage } = useClientPagination(timesheets, LIMIT, 1)

  return (
    <CustomTable
      fields={TIMESHEET_FIELDS}
      data={currentData}
      onPage={goToPage}
      page={currentPage}
      total={timesheets?.length}
      pageSize={LIMIT}
    />
  )
}
