// Accepted duration formats: HH:MM (hours and minutes), HH.Decimal and HH
export const getMinutes = (hours) => {
  let value = 0
  switch (true) {
    case /^\d+[.,]?\d*$/.test(hours): {
      const [h, ratio] = hours.split(/[,.]/)
      value = Number(h) * 60 + (ratio ? Math.floor(Number(`0.${ratio}`) * 60) : 0)
      break
    }
    case /^\d+:\d*$/.test(hours): {
      const [h, minutes] = hours.split(':')
      value = Number(h) * 60 + (minutes ? Number(minutes) : 0)

      break
    }
    default:
      value = hours
      break
  }
  return value
}
// +1 for scale starting with 1 and +1 for header row
export const getLineNumberFromIndex = (index) => index + 2

export const groupErrors = (rawErrors, lineMapping) => {
  const groupedErrorsMap = new Map()
  rawErrors.forEach((error) => {
    const key = error.record_index
    const existingGroup = groupedErrorsMap.get(key)
    groupedErrorsMap.set(key, [...(existingGroup ?? []), error])
  })

  const tableFormat = Array.from(groupedErrorsMap, ([record_index, errors]) => ({
    line_number: lineMapping[record_index],
    errors,
  }))
  return tableFormat
}

export const filterEmailDuplicates = (errors) =>
  errors.filter((error) => {
    const isEmailDataError = !error.field && error.message.includes('email')
    const hasEmailValidationError = errors.find((err) => err.field === 'timesheet_fields.email')
    return !(isEmailDataError && hasEmailValidationError)
  })
