import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import Typography from '@atoms/Typography/Typography'
import { bulkUploadTimesheets } from '@services/timesheets.service'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ConfirmTimesheetUpload } from './ConfirmTimesheetUpload'
import { EMPTY_CSV_VALUE, EMPTY_VALUES } from './constants'
import { IdentifyColumns } from './IdentifyColumns'
import { UploadCSV } from './UploadCSV'
import { ValidateTimesheet } from './ValidateTimesheet'

const STEPS = {
  Upload: { value: 1, label: 'Upload CSV' },
  Identify: { value: 2, label: 'Identify columns' },
  Validate: { value: 3, label: 'Validate timesheet' },
  Confirm: { value: 4, label: 'Confirm upload' },
}

export const UploadTimesheetsModal = ({ onClose }) => {
  const navigate = useNavigate()

  const [activeStep, setStep] = useState(1)
  const [payload, setPayload] = useState({})
  const [validationErrors, setValidationErrors] = useState([])
  // Matches line index in errors to line number in original csv file
  const [lineMapping, setLineMapping] = useState([])

  const handleBack = () => {
    setStep((prev) => prev - 1)
  }
  const goNextStep = () => setStep((prev) => prev + 1)

  const handleReset = () => {
    setStep(1)
    setPayload({})
    payload.handleReset()
    setValidationErrors([])
  }

  const bulkUploadTimesheetsMutation = useMutation({
    mutationFn: bulkUploadTimesheets,
    onSuccess: (res, vars) => {
      const hasErrors = Boolean(res)
      const isValidateOnly = vars.validate_only
      // filter out errors with messages containing EMPTY_CSV_VALUE as backend sends both data and validation errors for a missing value
      if (hasErrors) {
        const errors = res.error
          .filter((err) => !err.message.includes(EMPTY_CSV_VALUE))
          .sort((a, b) => a.record_index - b.record_index)
        setValidationErrors(errors)
        setStep(STEPS.Validate.value)
        return
      }

      setValidationErrors([])
      if (isValidateOnly) {
        setStep(STEPS.Validate.value)
        return
      }
      onClose()
      navigate('/pages/timesheets')
      toast.success('Timesheets successfully uploaded')
    },
  })

  const updatePayload = (formValues) => {
    if (formValues) {
      setPayload((prev) => ({
        ...prev,
        ...formValues,
      }))
    }
  }
  const handleNext = (formValues) => {
    updatePayload(formValues)
    goNextStep()
  }

  const submitTimesheet = (values, validateOnly = false) => {
    const mapping = []
    const timesheetsToSubmit = values.timesheetFields.reduce((accum, { email, aggregated_minutes, lineNumber }) => {
      const emailIsEmpty = EMPTY_VALUES.includes(email.trim())
      const minutesIsEmpty = EMPTY_VALUES.includes(aggregated_minutes)
      const shouldKeep = !emailIsEmpty || !minutesIsEmpty

      if (!shouldKeep) {
        return accum
      }
      mapping.push(lineNumber)
      accum.push({ email, aggregated_minutes })
      return accum
    }, [])

    const timesheetPayload = {
      start_period: values.startDate,
      end_period: values.endDate,
      timesheet_fields: timesheetsToSubmit,
      ...(validateOnly && { validate_only: true }),
    }

    setLineMapping(mapping)
    updatePayload({ timesheetFields: timesheetsToSubmit })
    bulkUploadTimesheetsMutation.mutate(timesheetPayload)
  }

  const onCreate = () => submitTimesheet(payload)

  const validateValues = (formValues) => {
    const newValues = { ...payload, ...formValues }
    setPayload(newValues)
    submitTimesheet(newValues, true)
  }

  return (
    <Modal show onClose={onClose} fullscreen>
      <Modal.Body className="p-0">
        <Stepper
          activeStep={activeStep}
          title="Upload timesheet"
          description={<Typography className="mt-4">Please fill in the details of your timesheets.</Typography>}
        >
          <Step step="1" title={STEPS.Upload.label} setStep={setStep}>
            <StepContent title={STEPS.Upload.label} onClose={onClose}>
              <UploadCSV onNext={handleNext} />
            </StepContent>
          </Step>

          <Step step="2" title={STEPS.Identify.label} setStep={setStep}>
            <StepContent title={STEPS.Identify.label} onClose={onClose} onBack={handleBack}>
              <IdentifyColumns
                payload={payload}
                onNext={validateValues}
                onReUpload={handleReset}
                isValidating={bulkUploadTimesheetsMutation.isLoading}
              />
            </StepContent>
          </Step>

          <Step step="3" title={STEPS.Validate.label} setStep={setStep}>
            <StepContent title={STEPS.Validate.label} onClose={onClose} onBack={handleBack}>
              <ValidateTimesheet
                payload={payload}
                errors={validationErrors}
                lineMapping={lineMapping}
                onNext={goNextStep}
                onReUpload={handleReset}
              />
            </StepContent>
          </Step>
          <Step step="3" title={STEPS.Confirm.label} setStep={setStep}>
            <StepContent title={STEPS.Confirm.label} onClose={onClose} onBack={handleBack}>
              <ConfirmTimesheetUpload onConfirm={onCreate} isCreating={bulkUploadTimesheetsMutation.isLoading} />
            </StepContent>
          </Step>
        </Stepper>
      </Modal.Body>
    </Modal>
  )
}
