import './Table.scss'

import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'

import { CorrectDataTable } from './CorrectDataTable'
import { ErrorsTable } from './ErrorsTable'

export const ValidateTimesheet = ({ payload, onNext, onReUpload, errors, lineMapping }) => {
  const { timesheetFields = [] } = payload
  const withErrors = Boolean(errors.length)

  return (
    <div style={{ width: 850 }}>
      <div className="mb-5">
        <Typography classname="light_regular_normal__16">
          {withErrors
            ? 'Some errors were found in your file. Please review the issues, make the necessary corrections, and reupload the file.'
            : 'Please confirm your timesheet and the Email and total hours columns for input are correct.'}
        </Typography>
      </div>
      {withErrors ? (
        <ErrorsTable errors={errors} lineMapping={lineMapping} />
      ) : (
        <CorrectDataTable timesheetFields={timesheetFields} />
      )}
      <div className="d-flex pb-5 justify-content-center gap-4 mt-5">
        <Button onClick={onReUpload} priority={withErrors ? 'primary' : 'secondary'} className="align-self-end">
          Re-upload
        </Button>
        {!withErrors && (
          <Button onClick={onNext} type="submit" className="align-self-end">
            Next
          </Button>
        )}
      </div>
    </div>
  )
}
