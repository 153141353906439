import Typography from '@atoms/Typography/Typography'

import { IdentifyColumnTable } from './IdentifyColumnTable'

export const IdentifyColumns = ({ payload, onNext, onReUpload, isValidating }) => {
  const { csvData, csvColumns, csvFile } = payload

  return (
    <div className="d-flex flex-column gap-4 " style={{ width: 850 }}>
      <div style={{ width: 620 }}>
        <Typography className="light_regular_normal__16 mb-4">
          Please review you timesheet and identify the Email and Total hours columns for input. You can either provide
          the new value here or correct the errors in your CSV and then{' '}
          <span className="color_secondary_light">re-upload your file</span>.
        </Typography>
      </div>

      {csvColumns && csvData && csvFile && (
        <IdentifyColumnTable
          csvColumns={csvColumns}
          csvData={csvData}
          onNext={onNext}
          csvFile={csvFile}
          onReUpload={onReUpload}
          isValidating={isValidating}
        />
      )}
    </div>
  )
}
