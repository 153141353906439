import '../style.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import CustomTable from '@atoms/Table/CustomTable'
import { useApp } from '@core/context'
import usePagination from '@core/hooks/usePagination'
import { add } from '@core/icons/icons'
import { fetchContractors } from '@services/app.service'
import { createDraftContractForContractor, updateDraftContractForContractor } from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import MilesTonesImg from '../../../../../assets/images/milestones.svg'
import { DeleteMilestoneConfirmModal } from './DeleteMilestoneConfirmModal'
import { MilestoneModal } from './MilestoneModal'
import { getMilestoneFields } from './utils'

export const MilestonesForm = ({
  onNext,
  initValue,
  loading,
  onClose,
  draft,
  draftId,
  isDraftContractLoading,
  setSaveDraftCallBack,
}) => {
  const [milestones, setMilestones] = useState([])
  const [milestonesPerPage, setMilestonesPerPage] = useState([])
  const [selectedMilestone, setSelectedMilestone] = useState(null)

  const { currencies, profile } = useApp()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const milestoneAddOrEditModalOpen = useBoolean(false)
  const currency = currencies.find((item) => item.id === initValue.compensation?.currency)
  const selectedCurrency = currency?.sign ? currency.sign : currency?.short_code || ''

  const { page, limit, setPage } = usePagination({ page: 1, limit: 5 })

  const deleteConfirmOpen = useBoolean(false)
  const [selectedMilestoneDelete, setSelectedMilestoneDelete] = useState(null)

  const refetchContractorsList = () => {
    queryClient.refetchQueries(fetchContractors.key)
    onClose()
    navigate('/pages/team')
  }

  const createDraftContract = useMutation({
    mutationFn: (payload) => createDraftContractForContractor(payload),
    onSuccess: () => {
      refetchContractorsList()
    },
  })

  const updateDraftContract = useMutation({
    mutationFn: (payload) => updateDraftContractForContractor(draftId, payload),
    onSuccess: () => {
      refetchContractorsList()
    },
  })

  const isContractorsListLoading = createDraftContract.isLoading || updateDraftContract.isLoading

  useEffect(() => {
    if (draft && draft.milestones) {
      const formattedMilestones = draft.milestones.map((item) => ({
        ...item,
        milestone: item.title,
        milestone_id: v4(),
        attachment: {
          file: {
            name: item.attachment?.filename,
          },
          uuid: item.attachment?.file_uuid,
        },
      }))
      setMilestones(formattedMilestones)
    }
  }, [draft])

  const handleEdit = useCallback(
    (event, selectedID) => {
      event.stopPropagation()
      const match = milestones.find((item) => item.milestone_id === selectedID)
      setSelectedMilestone(match)
      milestoneAddOrEditModalOpen.setTrue()
    },
    [milestones, milestoneAddOrEditModalOpen]
  )

  const handleDelete = useCallback(
    (event, selectedID) => {
      event.stopPropagation()
      setSelectedMilestoneDelete(selectedID)
      deleteConfirmOpen.setTrue()
    },
    [deleteConfirmOpen]
  )

  const fields = useMemo(
    () => getMilestoneFields(handleEdit, handleDelete, selectedCurrency),
    [selectedCurrency, handleDelete, handleEdit]
  )

  useEffect(() => {
    const start = (page - 1) * limit
    const end = start + limit
    setMilestonesPerPage(milestones.slice(start, end))
  }, [page, limit, milestones])

  const handleSkip = () => {
    onNext(initValue)
    if (setSaveDraftCallBack) {
      setSaveDraftCallBack(null)
    }
  }

  const handleAddContractor = () => {
    const payload = {
      ...initValue,
      milestones: milestones.map((item) => ({
        milestone: item.milestone,
        deadline: moment(item.deadline).format('YYYY-MM-DD'),
        payment_amount: Number(item.payment_amount),
        description: item.description,
        attachment: item.attachment?.uuid,
      })),
    }
    onNext(payload)
    if (setSaveDraftCallBack) {
      setSaveDraftCallBack(null)
    }
  }

  const handleSaveDraft = () => {
    const payload = {
      ...(draft?.contractor_draft_contract_raw_data || initValue),
      milestones: milestones.map((item) => ({
        milestone: item.milestone,
        deadline: moment(item.deadline).format('YYYY-MM-DD'),
        payment_amount: Number(item.payment_amount),
        description: item.description,
        attachment: item.attachment?.uuid,
      })),
      company: profile.id,
    }
    if (draft?.contractor_draft_contract_raw_data || initValue?.contractor_draft_contract_raw_data) {
      updateDraftContract.mutate({ ...payload, raw_data: payload }) // BE needs this aproach
    } else {
      createDraftContract.mutate({ ...payload, raw_data: payload }) // BE needs this aproach
    }
  }

  useEffect(() => {
    if (setSaveDraftCallBack) {
      if (milestones.length >= 1) {
        setSaveDraftCallBack(() => () => handleSaveDraft())
      } else {
        setSaveDraftCallBack(null)
      }
    }
  }, [milestones.length])

  const handleDeleteMilestone = () => {
    setMilestones((prevMilestones) => {
      const updatedMilestones = prevMilestones.filter((milestone) => milestone.milestone_id !== selectedMilestoneDelete)
      const totalItems = updatedMilestones.length
      const totalPages = Math.ceil(totalItems / limit)
      if (page > totalPages) {
        setPage(totalPages <= 0 ? 1 : totalPages)
      }
      return updatedMilestones
    })
    deleteConfirmOpen.setFalse()
  }

  if (isDraftContractLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={32} speed={1} color="black" />
      </div>
    )
  }

  const handleCloseNewMilestoneModal = () => {
    milestoneAddOrEditModalOpen.setFalse()
    setSelectedMilestone(null)
  }

  return (
    <div style={{ height: '80%', width: '80%' }}>
      {milestones.length ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
            <Button
              onClick={milestoneAddOrEditModalOpen.setTrue}
              className="text_regular__14 px-2 pl-0"
              priority="secondary"
              size="small"
              style={{ height: '40px' }}
            >
              <Icon icon={add} width={25} className="mr-2" />
              Add another milestone
            </Button>
          </div>
          <CustomTable
            fields={fields}
            page={page}
            total={milestones.length || 0}
            onPage={setPage}
            pageSize={limit}
            data={milestonesPerPage || []}
          />
          <div style={{ width: 'auto', maxWidth: 840 }}>
            <NotificationBlock text="These milestones can be tracked, accepted and rejected from the contractor section within your Remofirst account." />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '64px', gap: '20px' }}>
            {(!draft || (draft && draft.state === 'draft')) && (
              <Button
                priority="secondary"
                onClick={handleSaveDraft}
                className="align-self-end"
                disabled={isContractorsListLoading}
              >
                {isContractorsListLoading ? 'Loading...' : 'Save draft and exit'}
              </Button>
            )}
            <Button onClick={handleAddContractor} className="align-self-end" loading={loading} disabled={loading}>
              {loading ? 'Loading...' : 'Save and continue'}
            </Button>
          </div>
        </>
      ) : (
        <div className="milestones-wrapper">
          <img src={MilesTonesImg} alt="milestones" width={250} />
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <DotWave />
            </div>
          ) : (
            <div className="milestone-actions-wrapper">
              <Button onClick={handleSkip} priority="secondary">
                Add milestones later
              </Button>
              <Button onClick={milestoneAddOrEditModalOpen.setTrue}>
                <Icon className="mr-2" fill="#fff" width={20} height={20} icon={add} />
                Add milestone
              </Button>
            </div>
          )}
        </div>
      )}
      <MilestoneModal
        currency={selectedCurrency}
        setMilestones={setMilestones}
        selectedMilestone={selectedMilestone}
        setSelectedMilestone={setSelectedMilestone}
        show={milestoneAddOrEditModalOpen.value}
        onClose={handleCloseNewMilestoneModal}
      />
      <DeleteMilestoneConfirmModal
        show={deleteConfirmOpen.value}
        onHide={deleteConfirmOpen.setFalse}
        onConfirm={handleDeleteMilestone}
      />
    </div>
  )
}
