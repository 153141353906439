import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { AttachmentDropzone } from '@components/attachment/attachment-dropzone/attachment-dropzone'
import { InfoMessage } from '@components/info-message/info-message.component'
import { useApp } from '@core/context'
import { fetchPostFile } from '@services/app.service'
import { fetchCreateContractor, patchContractById } from '@store/contracts'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import { ReactComponent as ContractUploadSuccessIcon } from '../../../../../assets/icons/contract-upload-success-icon.svg'

const imageFileExtensions = ['image/jpeg', 'image/png', '.jpg', '.jpeg', '.png']

export const UploadContract = ({ onClose, contractor, draftContractId }) => {
  const queryClient = useQueryClient()
  const { profile } = useApp()
  const navigate = useNavigate()

  const refetchNotifications = () => {
    queryClient.invalidateQueries('notifications', { refetchActive: true })
  }
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFileUuid, setSelectedFileUuid] = useState(null)
  const successModal = useBoolean(false)

  const temporaryFilesMutation = useMutation({
    mutationFn: fetchPostFile,
  })

  const inviteNewContractor = useMutation({
    mutationFn: (body) => fetchCreateContractor(body),
  })

  const contractUploadMutation = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: () => {
      successModal.setTrue()
      refetchNotifications()
    },
  })

  const isCompleteSetupBtnDisabled = inviteNewContractor.isLoading || contractUploadMutation.isLoading

  const handleDrop = ([file]) => {
    temporaryFilesMutation
      .mutateAsync(file)
      .then(({ file_uuid }) => setSelectedFileUuid(file_uuid))
      .then(() => setSelectedFile(file))
  }
  const handleRemoveFile = () => setSelectedFile(null)

  const onSave = () => {
    const currencyIsObj = typeof contractor.compensation?.currency === 'object'
    const payload = {
      id: draftContractId || contractor.id,
      data: {
        company: profile.id,
        compensation: {
          currency: currencyIsObj ? contractor.compensation.currency.id : contractor.compensation.currency,
          contractor_rate: contractor.compensation?.contractor_rate,
          estimated_working_hours: contractor.compensation?.estimated_working_hours,
          contractor_wage_type: contractor.compensation?.contractor_wage_type,
          contractor_payment_frequency: contractor.compensation?.contractor_payment_frequency,
        },
        job: contractor.job,
        contract_type: contractor.contract_type,
        working_country: contractor.working_country.id || contractor.working_country,
      },
    }

    const formattedPayload = {
      ...payload,
      data: { ...payload.data, temporary_file: selectedFileUuid, raw_data: payload.data },
    }

    if (draftContractId) {
      contractUploadMutation.mutate({
        data: {
          ...formattedPayload.data,
          ...(draftContractId && {
            contractor_draft_contract: draftContractId,
          }),
          company: profile.id,
        },
        id: formattedPayload.id,
      })
    } else {
      contractUploadMutation.mutate(formattedPayload)
    }
  }

  const handleClose = () => {
    onClose()
    navigate('/pages/agreements')
  }

  if (successModal.value) {
    return (
      <div className="d-flex flex-column align-items-center">
        <ContractUploadSuccessIcon />
        <Typography className="heading_semibold__16 mb-2">
          {draftContractId ? 'Contract uploaded' : 'Contract uploaded and sent!'}
        </Typography>
        <Typography style={{ color: '#6d6d6c', marginBottom: 4 }}>
          Track the progress of your contract from your agreements section.
        </Typography>
        <Button data-testid="upload-contract-AA20CB" className="mx-auto mt-5" onClick={handleClose}>
          Complete setup
        </Button>
      </div>
    )
  }

  return (
    <div>
      <Typography className="heading_semibold__16 mb-2">Upload contract</Typography>
      <Typography style={{ color: '#6d6d6c', marginBottom: 4 }}>
        Please upload contract that was already signed with a contractor
      </Typography>
      <AttachmentDropzone
        multiple={false}
        accept={imageFileExtensions.join(',')}
        file={selectedFile}
        isLoading={temporaryFilesMutation.isLoading || contractUploadMutation.isLoading}
        onDrop={handleDrop}
        onDelete={handleRemoveFile}
      >
        Permitted files .jpeg, .png. .pdf no more than 50 MB
      </AttachmentDropzone>
      <InfoMessage message={<span>Remofirst holds no legal responsibility for the uploaded contracts.</span>} />
      <div className="pb-5 align-self-center d-flex">
        {selectedFile ? (
          <Button
            data-testid="upload-contract-69505C"
            className="m-auto"
            onClick={onSave}
            disabled={isCompleteSetupBtnDisabled}
          >
            {isCompleteSetupBtnDisabled ? 'Loading...' : 'Complete setup'}
          </Button>
        ) : (
          <Button data-testid="upload-contract-534774" className="m-auto" priority="secondary" onClick={onClose}>
            Add contract later
          </Button>
        )}
      </div>
    </div>
  )
}
