import CustomTable from '@atoms/Table/CustomTable'
import { useClientPagination } from '@core/hooks/useClientPagination'
import { useMemo } from 'react'
import { Stack } from 'react-bootstrap'

import { FIELD_LABELS, LIMIT } from './constants'
import { filterEmailDuplicates, groupErrors } from './helpers'

const ValidationError = ({ error }) => {
  const { field, message } = error
  const fieldLabel = FIELD_LABELS[field] ? <strong>{`${FIELD_LABELS[field]} -`}</strong> : null

  return (
    <Stack direction="horizontal" gap={1}>
      {fieldLabel}
      {message}
    </Stack>
  )
}

export const ERRORS_FIELDS = [
  {
    title: 'CSV line',
    key: 'line_number',
    type: 'string',
    maxWidth: 70,
    minWidth: 70,
  },
  {
    title: 'Error(s)',
    key: 'errors',
    type: 'custom',
    maxWidth: 400,
    minWidth: 200,
    render: ({ errors }) => (
      <Stack gap={1}>
        {errors?.map((error) => (
          <ValidationError error={error} />
        ))}
      </Stack>
    ),
  },
]

export const ErrorsTable = ({ errors, lineMapping }) => {
  const errorTableEntries = useMemo(() => {
    const grouped = groupErrors(errors, lineMapping)
    const cleanGrouped = grouped.map((group) => ({ ...group, errors: filterEmailDuplicates(group.errors) }))
    return cleanGrouped
  }, [errors, lineMapping])

  const { currentPage, currentData, goToPage } = useClientPagination(errorTableEntries, LIMIT, 1)

  return (
    <CustomTable
      fields={ERRORS_FIELDS}
      data={currentData}
      onPage={goToPage}
      page={currentPage}
      total={errorTableEntries?.length}
      pageSize={LIMIT}
    />
  )
}
